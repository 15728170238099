import Column from "../components/Column";
import {Stack} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useUserAuth} from "../contexts/UserAuthContext";
import {useEffect} from "react";
import {auth} from '../firebase';

const VerifyEmail = () => {

    const {user, verifyEmail, logOut} = useUserAuth();
    const navigate = useNavigate();

    if (!user) {
        navigate('/');
    }

    if (auth.currentUser?.emailVerified) {
        navigate("/account");
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!!auth.currentUser) {
                auth.currentUser.reload().then(() => {
                        if (auth.currentUser?.emailVerified) {
                            navigate("/account");
                        }
                    }
                ).catch(() => {});
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Column>
            <Stack className="d-flex justify-content-center text-center vh-custom" gap={3}>
                <h2>Verify Email</h2>
                <p>Verification email was sent to <span className="text-primary fw-bold">{user?.email}</span>. Please, check
                    your Inbox for an email from <span className="text-primary fw-bold">s-wipe.io</span> and follow the provided
                    link.</p>

                <p>If you didn't receive the verification email, <Link className="fw-bold"
                    onClick={() => verifyEmail(user)} to="/">request</Link> another one.</p>

                <p>Alternatively, try to <Link to="/" className="fw-bold" onClick={() => logOut()}>sign up</Link> again.</p>
            </Stack>
        </Column>
    )
}

export default VerifyEmail;